import { render, staticRenderFns } from "./f-server-form-loader.vue?vue&type=template&id=7a214e33&"
import script from "./f-server-form-loader.vue?vue&type=script&lang=ts&"
export * from "./f-server-form-loader.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/deploy/scalefish/releases/253/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7a214e33')) {
      api.createRecord('7a214e33', component.options)
    } else {
      api.reload('7a214e33', component.options)
    }
    module.hot.accept("./f-server-form-loader.vue?vue&type=template&id=7a214e33&", function () {
      api.rerender('7a214e33', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "assets/js/components/form/f-server-form-loader.vue"
export default component.exports